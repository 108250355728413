import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
const UploadImg = require("../../global_assets/upload_icon.png")
const pdfIcon = require("../assets/image_pdf.png")
const FileDelete = require("../assets/fileDelete.png")
export const CrossIcon = require("../../global_assets/crossblack.png");


{/* @ts-ignore */ }
import ProgressBar from "@ramonak/react-progress-bar";
{/* @ts-ignore */ }
import Dropzone from "react-dropzone";


// Customizable Area Start


// Customizable Area End

import FileAttachmentController, {
  Props,
} from "./FileAttachmentController.web";

export default class FileAttachment extends FileAttachmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={webStyle.Upload_div}>
          <Box p={2} sx={{ flexGrow: 1 }} style={{
            height: '38vh',
            textAlign: 'center',
            backgroundColor: 'white',
            overflowY: 'hidden',
            borderRadius: '17px',
            width: '193px',
            padding: '15px',
          }}>
            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: 'Roboto Mono' }}>Upload your documents</Typography>
            <Dropzone 
                data-test-id="txtInput" 
                accept="application/pdf"
                onDrop={(acceptedFiles: any, rejectedFiles: any) => {
                  console.log(rejectedFiles, rejectedFiles.length); 
                  if(rejectedFiles.length > 0) {
                    this.setState({rejectedFiles: true})
                  } 
                  this.ondroped(acceptedFiles)
                }
              }
            >
              
              {/* @ts-ignore */}
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input  {...getInputProps()} multiple type="file" accept='application/pdf' />
                    <Box my={2}>
                      <img src={UploadImg} alt={UploadImg} style={{ width: '84px' }} />
                    </Box>
                    <Box my={2}>
                      <Typography variant="subtitle1" style={{ color: '#807670', fontFamily: 'Roboto Mono' }} >Drag and drop your documents here</Typography>
                    </Box>
                  </div>
                </section>
              )}
            </Dropzone>
            
        {
          <Dialog
            PaperProps={{
              style: { borderRadius: 17, boxShadow: "none" },
            }}
            open={this.state.rejectedFiles}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box style={{ backgroundColor: "#002CFF" }}>
              <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                <img
                  src={CrossIcon}
                  alt=""
                style={webStyle.cross_img}
                onClick={() => {
                  this.setState({rejectedFiles: false})
                }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                p={4}
                width={420}
              >
                <Box style={{ textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "Roboto Mono", color: "white" }}
                  >
                    Only PDF files can be uploaded
                  </Typography>
                  
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ gap: "52px", marginTop: "50px" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                  style={webStyle.no_Btn}
                  onClick={() => {
                    this.setState({rejectedFiles: false})
                  }}
                  >
                    ok
                  </Button>
                  
                </Box>
              </Box>
            </Box>
          </Dialog>
        }
            <Button variant="contained"
              component="label"
              style={webStyle.uploadButton}
            >
              Upload document(s)
              <input type="file"  id="resetInput" data-test-id="UploadFile" onChange={this.onFileChange} onClick={(e:any) => { e.target.value = null; }} accept='application/pdf' capture="capture" multiple hidden />
            </Button>
          </Box>
          {this.state.showUploads && (this.state.uploadFileDisplay.length !== 0 || this.state.finalUploadfile.length !== 0) && (
            <div style={{
              flexGrow: 1,
              height: '38vh',
              textAlign: 'center',
              backgroundColor: 'white',
              borderRadius: '17px',
              width: '193px',
              padding: '15px',
              paddingRight: '0px',
              paddingTop: '0px',
              paddingBottom: '30px',
              overflow: 'hidden'
            }} >
              <Box p={2} style={{
                height: '38vh',
                textAlign: 'center',
                backgroundColor: 'white',
                overflowY: 'auto',
                borderRadius: '14px',
                width: 'auto',
                padding: '15px',
              }} >
               {this.state.uploadFileDisplay.length !== 0 ?
                <Typography variant="h5" style={{
                  fontSize: '1rem',
                  textAlign: "left",
                  padding: "10px",
                  fontFamily: 'Roboto Mono'
                }}>Uploading {this.state.uploadFileDisplay.length} Documents</Typography>
                :
                 <Typography variant="h5" style={{
                  fontSize: '1rem',
                  textAlign: "left",
                  padding: "10px",
                  fontFamily: 'Roboto Mono'
                }}>Uploaded {this.state.finalUploadfile.length } Documents</Typography>
              }
                {this.state.uploadFileDisplay.length > 0 && this.state.uploadFileDisplay?.map((file: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <div 
                      style={
                        webStyle.pdfName
                        } 
                        >
                        <div>
                          <img style={webStyle.pdfIcon} src={pdfIcon} alt={pdfIcon} />
                          </div>
                        <div
                         style={{ 
                          flexGrow: 1, 
                          display: 'inline-block'
                           }}
                           >
                          <div 
                          style={{ display: 'flex', 
                          fontSize: '1rem', 
                          color: 'rgb(128, 118, 112)' }}>
                            <p 
                            style={{ 
                              flexGrow: 1,
                               textAlign: 'start',
                                fontSize: '0.8rem', fontFamily: 'Roboto Mono' }}>
                                  {this.removeExtension(file.data.name.substring(0, 15))}
                                  </p>
                            <p style={{ flexGrow: 1, textAlign: 'end', color: file.p < 80 ? 'red' : 'green' }}>{file.p}%</p>
                          </div>
                          <ProgressBar customLabelStyles={{ display: 'none' }} height="9px" bgColor={file.p < 80 ? 'red' : 'green'}
                            completed={file.p} />
                        </div>
                        <div 
                        style={{
                           marginTop: '10px',
                            marginLeft: '12px', 
                            transform: 'scale(1.2)' 
                            }}
                            >
                          <img src={FileDelete} style={{ height: '3vh', marginTop: '17px', cursor: 'pointer', }} />
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
                {this.state.finalUploadfile.length > 0 && this.state.finalUploadfile?.map((file: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <div style={webStyle.pdfName} >
                        <div><img style={webStyle.pdfIcon} src={pdfIcon} alt={pdfIcon} /></div>
                        <div style={{ flexGrow: 1, display: 'inline-block' }}>
                          <div style={{ display: 'flex', fontSize: '1rem', color: 'rgb(128, 118, 112)' }}>
                            <p style={{ flexGrow: 1, textAlign: 'start', fontSize: '0.8rem', fontFamily: 'Roboto Mono' }}>{this.removeExtension(file.data.name.substring(0, 15))}</p>
                            <p style={{ flexGrow: 1, textAlign: 'end', color: file.p < 80 ? 'red' : 'green' }}>{file.p}%</p>
                          </div>
                          <ProgressBar customLabelStyles={{ display: 'none' }} height="9px" bgColor={file.p < 80 ? 'red' : 'green'}
                            completed={file.p} />
                        </div>
                        <div style={{ marginTop: '10px', marginLeft: '12px', transform: 'scale(1.2)' }}>
                          <img src={FileDelete} data-test-id="deleteFile" style={{ height: '3vh', marginTop: '17px', cursor: 'pointer', }}
                            onMouseOver={(e) => {e.currentTarget.style.opacity = "0.5"}}
                            onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                            onClick={() => this.handleDocumentRemove(file.id, file.id)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </Box>
            </div>
          )}
        </div>
     
        {
          this.state.maxSizePopUpopen &&
          <Dialog PaperProps={{ style: { borderRadius: 17, boxShadow: "none" },}}
            open={this.state.maxSizePopUpopen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Box 
            style={{
               backgroundColor: "#002CFF" 
               }}
               >
              <Box 
              display="flex" 
              justifyContent="flex-end" 
              mr={2} mt={2}>
                <img src={CrossIcon} alt="" style={webStyle.cross_img} onClick={this.close} />
              </Box>
              <Box display="flex" justifyContent="center" flexDirection="column"  p={4} width={420} >
                <Box style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ fontFamily: "Roboto Mono", color: "white" }} >
                   File {this.state.maxSizeFileName}{" "} size exceeds the limit.  </Typography>
                  <Typography variant="h5" style={{ fontFamily: "Roboto Mono", color: "white" }} >
                   Please upload file of less than 10 MB </Typography>
                  
                </Box>
                <Box display="flex"
                  justifyContent="center"
                  style={{ 
                    gap: "52px", 
                    marginTop: "50px"
                   }}
                >
                  <Button  color="primary" variant="contained"
                  style={webStyle.no_Btn}
                  onClick={this.close}
                  >
                    ok
                  </Button>
                  
                </Box>
              </Box>
            </Box>
          </Dialog>
        }
         {
          this.state.pdfValidationPopUp &&
          <Dialog
            PaperProps={{
              style: { borderRadius: 17, boxShadow: "none" },
            }}
            open={this.state.pdfValidationPopUp}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box style={{ backgroundColor: "#002CFF" }}>
              <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                <img
                  src={CrossIcon}
                  onMouseOver={(e) => {e.currentTarget.style.opacity = "0.5"}}
                  onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                  alt=""
                style={webStyle.cross_img}
                onClick={this.PdfPopUpclose}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                p={4}
                width={420}
              >
                <Box style={{ textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "Roboto Mono", color: "white" }}
                  >
                    Selected one or more files already exists{" "}
                  </Typography>
                  
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ gap: "52px", marginTop: "50px" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                  style={webStyle.no_Btn}
                  onClick={this.PdfPopUpclose}
                  >
                    ok
                  </Button>
                  
                </Box>
              </Box>
            </Box>
          </Dialog>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  Upload_div: {
    display: 'flex',
    gap: '30px'
  },
  cross_img: {
    height: "5vh",
    cursor:'pointer'
  },
  uploadButton: {
    backgroundColor: '#7700A6',
    color: 'white',
    fontFamily: 'Roboto Mono'
  },
  pdfName: {
    marginLeft: '-15px',
    borderRadius: '17px',
    marginBottom: '12px',
    display: 'flex'
  },
  pdfIcon: {
    height: '3rem',
    width: '2.5rem',
    padding: "0.5rem",
    marginRight: "0.25rem"
  },
  no_Btn: {
    backgroundColor: "#7700A6",
    color: "white",
    width: "114px",
    fontFamily: "Roboto Mono",
  },
}

// Customizable Area End