// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import KeywordSearch from "../../blocks/KeywordSearch/src/KeywordSearch";
import KeywordExtractor from "../../blocks/KeywordExtractor/src/KeywordExtractor";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import TextSummariser from "../../blocks/TextSummariser/src/TextSummariser";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import MainScreen from '../../blocks/TextSummariser/src/MainScreen.web'
import LoginAccount from '../../blocks/email-account-registration/src/LoginAccount.web'
import ForgotPassword from '../../blocks/email-account-registration/src/ForgotPassword.web'




const routeMap = {
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},

DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
KeywordSearch:{
 component:KeywordSearch,
path:"/KeywordSearch"},
KeywordExtractor:{
 component:KeywordExtractor,
path:"/KeywordExtractor"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
TextSummariser:{
 component:TextSummariser,
path:"/TextSummariser"},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  MainScreen:{
    component: MainScreen,
    path: "/MainScreen",
  },
  LoginAccount:{
    component: LoginAccount,
    path: '/',
    exact: true
  },
  ForgotPassword:{
    component: ForgotPassword,
    path: '/ForgotPassword'
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;